import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';


import authBgImg from '../../assets/imgs/auth-background.png';
import notFoundImg from '../../assets/imgs/not-found.png';
import cupImg from '../../assets/imgs/takeaway-cup-iso-color.png';
import taxiImg from '../../assets/imgs/taxi.png';
import packageImg from '../../assets/imgs/food-pack.png';
import Navbar from '../../components/home-components/Navbar';

export const NotFoundPage: FC = () => {
    const navigate = useNavigate();
    const goBackToHome = () => {
        navigate('/');
    }
    return (
        <>
            <div className="w-full h-screen" style={{
                background: `url(${authBgImg})`,
                backgroundSize: "cover",
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
            }}>
                <div className="mx-auto w-11/12 sm:w-11/12 md:w-10/12 lg:10/12 py-8">
                    <Navbar />

                    <div className="my-8 flex justify-center">
                        <img src={notFoundImg} alt="not found" />
                    </div>
                    <p className="text-lg md:text-2xl lg:text-3xl font-bold text-center mb-4">Oops! Page Not Found!</p>
                    <p className="text-xs text-center text-darkGray mb-4">Looks like you’ve taken a wrong turn! While you’re here, how about some options to get back on track?</p>
                    
                    

                    <div className="mx-auto w-11/12 sm:w-11/12 md:w-10/12 lg:8/12">
                        <div className="my-4 flex justify-between gap-4">
                            <div>
                                <div className="border-[0.8px] border-darkGray rounded-[10px] p-4">
                                    <div className="flex justify-start gap-2 mb-4">
                                        <div>
                                            <img src={cupImg} width={"20px"} height={"20px"} alt="cup" />
                                        </div>
                                        <div>
                                            <p className="text-xs font-semibold">Hungry?</p>
                                        </div>
                                    </div>
                                    <p className="text-xs text-darkGray">
                                        Head over to our Food Ordering section to explore tasty dishes
                                    </p>
                                </div>
                            </div>

                            <div>
                                <div className="border-[0.8px] border-darkGray rounded-[10px] p-4">
                                    <div className="flex justify-start gap-2 mb-4">
                                        <div>
                                            <img src={packageImg} width={"20px"} height={"20px"} alt="cup" />
                                        </div>
                                        <div>
                                            <p className="text-xs font-semibold">Need groceries?</p>
                                        </div>
                                    </div>
                                    <p className="text-xs text-darkGray">
                                        Find fresh items in Grocery Shopping and get them delivered fast
                                    </p>
                                </div>
                            </div>

                            <div>
                                <div className="border-[0.8px] border-darkGray rounded-[10px] p-4">
                                    <div className="flex justify-start gap-2 mb-4">
                                        <div>
                                            <img src={taxiImg} width={"22px"} height={"22px"} alt="cup" />
                                        </div>
                                        <div>
                                            <p className="text-xs font-semibold">Looking for a ride?</p>
                                        </div>
                                    </div>
                                    <p className="text-xs text-darkGray">
                                        Book a quick and safe ride to your destination in Ride Booking.
                                    </p>
                                </div>
                            </div>
                            
                        </div>

                        <div className="mt-8 mb-4 flex justify-center">
                            <Button
                                sx={{
                                    width: '50%',
                                    fontSize: '11px'
                                }}
                                className="relative"
                                variant={'contained'}
                                color={'primary'}
                                style={{
                                    backgroundColor: '#5C188B',
                                    color: '#ffffff',
                                    borderRadius: '5px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    gap: 4,
                                    width: '250px',
                                    padding: '1rem'
                                }}
                                onClick={goBackToHome}
                            >
                                <span>GO BACK TO HOME</span>
                            </Button>
                    </div>
                    </div>
                </div>

                
            </div>
        </>
    )
}

export default NotFoundPage;